import React from 'react'

import Layout from '@components/layout/Layout'
import ReferenceComponent from '@components/references/reference.component'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SiteLink from '@components/SiteLink'

import impactCaregivers from '@assets/images/infographics/infographic-impact-on-caregivers-desktop.svg'
import impactCaregiversMobile from '@assets/images/infographics/infographic-impact-on-caregivers-mobile.svg'

import './burden-of-illness.scss'

const BurdenOfIllness = ({ location }) => {
  const references = [
    {
      content:
        'Maski K, Owens JA. Insomnia, parasomnias, and narcolepsy in children: clinical features, diagnosis, and management.',
      source: 'Lancet Neurol.',
      info: '2016;15(11):1170-1181.'
    },
    {
      content:
        'Maski K, Steinhart E, Williams D, et al. Listening to the patient voice in narcolepsy: diagnostic delay, disease burden, and treatment efficacy.',
      source: 'J Clin Sleep Med.',
      info: '2017;13(3):419-425.'
    },
    {
      content:
        'Aran A, Einen M, Lin L, Plazzi G, Nishino S, Mignot E. Clinical and therapeutic aspects of childhood narcolepsy-cataplexy: a retrospective study of 51 children.',
      source: 'Sleep.',
      info: '2010;33(11):1457-1464. '
    },
    {
      content:
        'Blackwell JE, Alammar HA, Weighall AR, Kellar I, Nash HM. A systematic review of cognitive function and psychosocial well-being in school-age children with narcolepsy.',
      source: 'Sleep Med Rev.',
      info: '2017;34:82-93. '
    },
    {
      content: 'Inocente CO, Gustin MP, Lavault S, et al. Depressive feelings in children with narcolepsy.',
      source: 'Sleep Med.',
      info: '2014;15(3):309-314.'
    },
    {
      content: 'Broughton WA, Broughton RJ. Psychosocial impact of narcolepsy.',
      source: 'Sleep.',
      info: '1994;17(8 suppl):S45-S49. '
    },
    {
      content: 'Guilleminault C, Pelayo R. Narcolepsy in prepubertal children.',
      source: 'Ann Neurol.',
      info: '1998;43(1):135-142. '
    },
    { content: 'Nevsimalova S. Narcolepsy in childhood.', source: 'Sleep Med Rev.', info: '2009;13(2):169-180. ' },
    {
      content:
        'Postiglione E, Antelmi E, Pizza F, Lecendreux M, Dauvilliers Y, Plazzi G. The clinical spectrum of childhood narcolepsy.',
      source: 'Sleep Med Rev.',
      info: '2018;38:70-85. '
    },
    {
      content: 'Inocente CO, Gustin M-P, Lavault S, et al. Quality of life in children with narcolepsy.',
      source: 'CNS Neurosci Ther.',
      info: '2014;20(8):763-771.'
    },
    {
      content: 'Posar A, Pizza F, Parmeggiani A, Plazzi G. Neuropsychological findings in childhood narcolepsy.',
      source: 'J Child Neurol.',
      info: '2014;29(10):1370-1376. '
    },
    {
      content:
        'Stores G, Montgomery P, Wiggs L. The psychosocial problems of children with narcolepsy and those with excessive daytime sleepiness of uncertain origin.',
      source: 'Pediatrics.',
      info: '2006;118(4):e1116-e1123. '
    }
  ]

  return (
    <Layout addedClass='page-burden-of-illness' location={location}>
      <div className="burden-of-illness-page-header">
        <div className="primary-container">
          <div className="burden-of-illness-header-text">
            <h1>A Unique Burden for Pediatric Patients With Narcolepsy</h1>
            <p>
              Children and adolescents with narcolepsy may experience a range of academic and psychosocial problems,
              including decreased academic performance, social difficulties, depression, anxiety, and attention deficit
              disorder/learning disabilities.<sup>1-3</sup>
            </p>
          </div>
        </div>
        <div
          className="burden-of-illness-criteria-image-container"
          role="image"
          aria-label="Burden of narcolepsy for pediatric patients banner"
        />
      </div>
      <div className="background-white">
        <section className="section-gradient" />
      </div>
      <div className="primary-container content-wrapper">
        <h2 className="section-header">Psychosocial Impairment</h2>
        <p>
          Children with narcolepsy are at risk for emotional problems, including depression, anxiety, and low
          self-esteem.<sup>4</sup> One study of 88 patients, 5 to 17.5 years of age, showed that 25% of children with
          narcolepsy have depressive symptoms.<sup>5</sup>
        </p>
        <p>
          Children and adolescents with narcolepsy can express embarrassment and loss of self-worth because of their
          symptoms.<sup>6</sup> <span className="no-wrap">They:</span>{' '}
        </p>
        <ul className="burden-symptom-list">
          <li>
            <span>Are often mislabeled as “lazy” and “unmotivated” by teachers and caregivers<sup>6,7</sup></span>
          </li>
          <li>
            <span>Can be socially withdrawn and feel isolated from family members and peers<sup>6</sup></span>
          </li>
          <li>
            <span>May believe that others do not understand or tolerate their symptoms<sup>6</sup></span>{' '}
          </li>
          <li>
            <span>Are often embarrassed when symptoms occur in a social setting, such as in class<sup>6</sup></span>
          </li>
          <li>
            <span>
              May become introverted,<sup>8</sup> or avoid social situations that may trigger cataplexy or draw attention to their
              excessive daytime sleepiness<sup>6</sup>
            </span>
          </li>
          <li>
            <span>
              May experience high levels of anxiety from frightening hallucinations, unexpected sleep, and cataplectic
              falls<sup>9</sup>
            </span>
          </li>
        </ul>
        <br/>
        <h3 className="survey-revealed">
          A brief psychiatric interview of 40 children (age ≥7 y) with narcolepsy with cataplexy revealed<sup>7</sup>:
        </h3>
        <div className="primary-card with-gradient">
          <div className="primary-card-top-gradient top" />
          <div className="primary-card-top-gradient left" />
          <div className="primary-card-top-gradient right" />
          <div className="primary-card-gradient-content">
            <div className="content-box">
              <div className="header">90%</div>
              <div className="content">
                desire to <span>hide their illness</span> from peers
              </div>
            </div>
            <div className="content-box">
              <div className="header">90%</div>
              <div className="content">
                are <span>ashamed</span> of their symptoms
              </div>
            </div>
            <div className="content-box">
              <div className="header">83%</div>
              <div className="content">
                consider narcolepsy a <span>significant handicap</span> to acceptance in school, athletics, and social
                activities
              </div>
            </div>
            <div className="content-box">
              <div className="header">80%</div>
              <div className="content">
                report <span>feelings of helplessness</span> with their symptoms
              </div>
            </div>
            <div className="content-box">
              <div className="header">80%</div>
              <div className="content">
                feel <span>depressed by their inability</span> to be the same as their peers despite not having any
                physical signs
              </div>
            </div>
          </div>
        </div>
        <h2 className="section-header">Decreased Academic Performance</h2>
        <p>
          Some children and adolescents with narcolepsy have been shown to have academic difficulties, fail/repeat a
          grade, and have absenteeism.<sup>10</sup> In addition, narcolepsy with cataplexy is a risk factor for the
          development of cognitive problems that may impact learning ability and academic achievement in school-aged
          children.<sup>4,11</sup>
        </p>
        <h2 className="section-header">Impact on Caregivers</h2>
        <p>
          Caregivers are often concerned about their child’s performance and emotional well-being.<sup>12</sup> They may
          be frustrated with their child’s behaviors related to excessive daytime sleepiness and may seek support from
          healthcare providers or school systems.<sup>6,7</sup> In a brief interview, caregivers of pediatric patients
          with narcolepsy reported<sup>7</sup>:
        </p>
        <div className="impact-caregivers-graphic mobile-only">
          <img src={impactCaregiversMobile} />
        </div>
        <div className="impact-caregivers-graphic desktop-only">
          <img src={impactCaregivers} />
        </div>
        <SiteLink 
          className="cta-tile-button btn-primary"
          to="/pediatric-patients/symptoms/"
          tracking="pediatric burden of illness, click, symptoms - burden of illness"
          liveRampTracking={true}
        >
          <div className="cta-tile-button-text">
            Read Next: <span style={{ textTransform: 'none', fontWeight: 'normal' }}>Symptoms</span>&nbsp;&nbsp;
          </div>
          <FontAwesomeIcon className="arrow-right" icon="angle-right" />
        </SiteLink>
      </div>
      <ReferenceComponent referenceList={references} />
    </Layout>
  )
}

export default BurdenOfIllness
